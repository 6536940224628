import { createPopper, Options, Instance } from '@popperjs/core'
import { useState, useRef, useEffect, useCallback, useMemo } from 'react'
declare global {
  interface Window {
    popper: any
  }
}
const defaultConfig: Partial<Options> = {
  placement: 'top',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 8],
      },
    },
  ],
}

interface IProps extends Partial<Options> {
  showOnHover?: boolean
  handleToggle?: (shown: boolean) => any
}

export const usePopper = <T extends HTMLElement, U extends HTMLElement = HTMLDivElement>({
  handleToggle,
  showOnHover = false,
  ...config
}: IProps = {}) => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const [instance, setInstance] = useState<Instance | undefined>()
  const popupRef = useRef<U>(null)
  const targetRef = useRef<T>(null)
  useEffect(() => {
    if (targetRef.current && popupRef.current) {
      const popper = createPopper(targetRef.current, popupRef.current, {
        ...defaultConfig,
        ...config,
      })
      window.popper = popper
      setInstance(popper)
      return () => {
        popper.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const tRef = targetRef.current
    const pRef = popupRef.current
    const show = () => {
      setIsShown(true)
    }
    const hide = () => {
      setIsShown(false)
    }
    if (showOnHover) {
      if (tRef) {
        tRef.addEventListener('mouseenter', show)
        tRef.addEventListener('mouseleave', hide)
      }
      if (pRef) {
        pRef.addEventListener('mouseenter', show)
        pRef.addEventListener('mouseleave', hide)
      }
    }
    return () => {
      tRef?.removeEventListener('mouseenter', show)
      tRef?.removeEventListener('mouseleave', hide)
      pRef?.removeEventListener('mouseenter', show)
      pRef?.removeEventListener('mouseleave', hide)
    }
  }, [showOnHover])

  const toggle = useCallback(
    (target?: HTMLElement | U, config?: Partial<Options>) => {
      if (target && popupRef.current) {
        if (instance) {
          instance.destroy()
        }
        const popper = createPopper(target, popupRef.current, {
          ...defaultConfig,
          ...config,
        })
        window.popper = popper
        setInstance(popper)
      }
      setIsShown((shown) => {
        handleToggle?.(!shown)
        return !shown
      })
      if (instance) {
        instance.update()
      }
    },
    [instance, handleToggle, setIsShown],
  )

  return useMemo(
    () => ({
      target: targetRef,
      ref: popupRef,
      isShown,
      toggle,
    }),
    [isShown, toggle],
  )
}
