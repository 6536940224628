import { Attendee, CustomerProfile, ExploreProgram } from '@citruscamps/citrus-client'
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import {
  faCalendarDay,
  faCaretDown,
  faCircle,
  faFileInvoice,
  faSignOut,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import React from 'react'
import { ROUTES } from '../../constants/routes'
import { useFetchInfiniteAttendees } from '../../hooks/useFetchAttendees'
import { usePopper } from '../../hooks/usePopper'
import { formatFullName } from '../../utils/formatters'
import { Icon } from '../Icon/Icon'
import { Popover } from '../Popover/Popover'
import { TruncatedText } from '../TruncatedText/TruncatedText'

interface IProps {
  customer: CustomerProfile
  program: ExploreProgram
  theme: 'light' | 'dark'
}

export const AccountButton = ({ customer, program, theme }: IProps): React.ReactElement => {
  const { target, toggle, ...tooltipProps } = usePopper<HTMLButtonElement>({
    placement: 'bottom-start',
  })
  const query = { program_id: program.id }
  // TODO: Use for customer notifications
  const hasAlert = false
  const { data: attendees } = useFetchInfiniteAttendees({ programId: program.id, fetchAll: true })
  const isUnconnected: boolean = !!(attendees || []).some(
    (a: Attendee) => !a.profiles.some((p) => p.program_id === program.id),
  )

  return (
    <>
      <button
        ref={target}
        id="sidebarIcon"
        className="btn btn-account d-flex my-2 shadow-sm text-decoration-none"
        type="button"
        onClick={() => toggle()}
        style={{
          textTransform: 'unset',
        }}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className="avatar avatar-xs">
          <Icon
            src={customer?.picture_url}
            className="avatar-img bg-light border rounded-circle shadow-sm"
            alt={customer?.first_name}
            placeholder="/imgs/placeholder-user.jpg"
          />
          {(hasAlert || isUnconnected) && (
            <FontAwesomeIcon
              className="text-danger"
              icon={faCircle}
              style={{ right: 0, bottom: 0, position: 'absolute' }}
            />
          )}
        </div>
        <span className="d-none d-lg-inline ml-2" style={{ maxWidth: 280 }}>
          <TruncatedText>{formatFullName(customer)}</TruncatedText>
        </span>
        <FontAwesomeIcon icon={faCaretDown} className="ml-2" transform="down-3" />
      </button>
      <Popover {...tooltipProps} toggle={toggle}>
        <Link
          href={{ pathname: ROUTES.PROFILE, query }}
          className="dropdown-item border-bottom text-decoration-none"
          passHref
        >
          <div className="d-flex w-100">
            <Icon
              src={customer?.picture_url}
              className="avatar-img avatar-sm bg-light border rounded-circle shadow-sm my-auto"
              alt={customer?.first_name}
              placeholder="/imgs/placeholder-user.jpg"
            />
            <TruncatedText className="float-left ml-2 my-auto">
              <TruncatedText className="font-weight-bold text-decoration-none h6 mt-2 mb-0">
                {formatFullName(customer)}
              </TruncatedText>
              <TruncatedText className="text-decoration-none text-muted">
                {customer.email}
              </TruncatedText>
              <div className="small">Citrus Account</div>
              {isUnconnected && (
                <div className="small text-danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                  Action required
                </div>
              )}
            </TruncatedText>
          </div>
        </Link>
        <Link
          href={{ pathname: ROUTES.SCHEDULE, query }}
          className="dropdown-item border-bottom text-decoration-none"
          passHref
        >
          <FontAwesomeIcon className="mr-3" fixedWidth={true} icon={faCalendarDay} />
          Schedule
        </Link>
        <Link
          href={{ pathname: ROUTES.ORDERS, query }}
          className="dropdown-item border-bottom text-decoration-none"
          passHref
        >
          <FontAwesomeIcon className="mr-3" fixedWidth={true} icon={faFileInvoice} />
          Orders History
        </Link>
        <Link
          href={{ pathname: ROUTES.LOGOUT, query }}
          className="dropdown-item text-decoration-none"
          passHref
        >
          <FontAwesomeIcon className="mr-3" fixedWidth={true} icon={faSignOut} />
          Logout
        </Link>
      </Popover>
    </>
  )
}
