import React, { PropsWithChildren, useState, useEffect } from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'

interface LoadingButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  loading?: boolean
  waitFor?: number
  style?: React.CSSProperties
  loadingIconProps?: FontAwesomeIconProps
  onClick?: (event?: React.MouseEvent) => Promise<any> | any
}

export const LoadingButton = (props: PropsWithChildren<LoadingButtonProps>): React.ReactElement => {
  let {
    type = 'button',
    className = 'btn btn-primary',
    children,
    onClick,
    waitFor = 300,
    loadingIconProps,
    loading: initialLoading,
    ...buttonProps
  } = props
  let [loading, setLoading] = useState<boolean>(initialLoading || false)

  useEffect(() => {
    if (typeof initialLoading !== 'undefined') {
      setLoading(initialLoading || false)
    }
  }, [initialLoading])
  const handleClick = async (e: React.MouseEvent): Promise<void> => {
    if (onClick) {
      const promise = onClick(e)
      const isAsync: boolean =
        promise && typeof promise.then === 'function' && promise[Symbol.toStringTag] === 'Promise'
      if (isAsync) {
        setLoading(true)
        try {
          await Promise.all([promise, new Promise((resolve) => setTimeout(resolve, waitFor))])
          setLoading(false)
        } catch {
          setLoading(false)
        }
      } else {
        onClick(e)
      }
    }
  }
  if (loading) {
    return (
      <button {...buttonProps} type={type} className={className} disabled={true}>
        <FontAwesomeIcon icon={faSpinner} className="mx-5" {...loadingIconProps} pulse />
        <span className="sr-only">Loading...</span>
      </button>
    )
  }
  return (
    <button {...buttonProps} type={type} className={className} onClick={handleClick}>
      {children}
    </button>
  )
}
