import React from 'react'
import { ExploreProgramFeatures } from '@citruscamps/citrus-client'
import { useFlags } from './hooks/useFlags'

interface IProps {
  flagKey: keyof ExploreProgramFeatures | (keyof ExploreProgramFeatures)[]
  fallback?: React.ReactNode
  check?: (value?: boolean | number | string, key?: string) => boolean
  checkAll?: (flags: ExploreProgramFeatures) => boolean
}

export const FeatureFlag = ({
  flagKey,
  fallback = null,
  check = (val) => !!val,
  checkAll,
  children = null,
}: React.PropsWithChildren<IProps>) => {
  const { flags, isLoading, isError } = useFlags()
  const isEnabled: boolean =
    !!flags &&
    (checkAll
      ? checkAll(flags)
      : Array.isArray(flagKey)
      ? flagKey.every((flagKey) => check(flags[flagKey], flagKey))
      : check(flags[flagKey], flagKey))
  if (isLoading || isError || !flags || !isEnabled) {
    return <>{fallback}</>
  }
  return <>{children}</>
}
