import {
  BatchNotificationOperation,
  ErrorResponse,
  Notification,
  NotificationList,
  NotificationTopicGroup,
  NotificationsApi,
} from '@citruscamps/citrus-client'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import React, { createContext, useContext, useState } from 'react'
import { DefaultHalfMainPagination } from '../../../constants/pagination'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { useRequestHandler } from '../../../hooks/useRequestHandler'
import { useToast } from '../../../hooks/useToast'
import { Pagination } from '../../../interfaces/pagination'
import translations from '../../../translations/en.json'
import { generateApiConfig } from '../../../utils/client-config'
import { generateBaseKey, generateListKey } from '../../../utils/key-generator'

const DefaultFetchProps: NotificationFetchProps = {
  sort: 'created',
  order: 'DESC',
  filter: {
    topic_group: ['program', 'product'],
  },
}

export const NotificationsContext: React.Context<FetchNotifications> =
  createContext<FetchNotifications>({
    data: [],
    error: null,
    fetchProps: DefaultFetchProps,
    isError: false,
    isLoading: false,
    isFetching: false,
    hasNextPage: false,
    hasUnread: false,
    pagination: DefaultHalfMainPagination,
    fetchNextPage(): Promise<InfiniteQueryObserverResult<NotificationList, ErrorResponse>> {
      throw new Error('Function not implemented.')
    },
    setFetchProps(value?: NotificationFetchProps | undefined): void {
      throw new Error('Function not implemented.')
    },
    handleMarkAllAsRead(): Promise<void> {
      throw new Error('Function not implemented.')
    },
    handleMarkAsRead(id: string): Promise<void> {
      throw new Error('Function not implemented.')
    },
    handleMarkAsUnread(id: string): Promise<void> {
      throw new Error('Function not implemented.')
    },
    handleArchiveAll(): Promise<void> {
      throw new Error('Function not implemented.')
    },
    handleArchive(id: string): Promise<void> {
      throw new Error('Function not implemented.')
    },
    handleUnarchive(id: string): Promise<void> {
      throw new Error('Function not implemented.')
    },
  })

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export const ProvideNotifications = ({
  children,
  ...props
}: React.PropsWithChildren<IFetchNotificationsProps>): React.ReactElement => {
  const event: FetchNotifications = useFetchNotifications(props)
  return <NotificationsContext.Provider value={event}>{children}</NotificationsContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useNotifications = (): FetchNotifications => {
  return useContext<FetchNotifications>(NotificationsContext)
}

export interface FilterProps {
  is_archived?: boolean
  is_read?: boolean
  topic_group?: NotificationTopicGroup[]
}

export interface NotificationFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: FilterProps
}

export interface IFetchNotificationsProps {
  programId: string
  pagination?: Pagination
  fetchProps?: NotificationFetchProps
}

interface FetchNotifications {
  data: Notification[]
  error: ErrorResponse | null
  fetchProps: NotificationFetchProps
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  hasNextPage: boolean
  hasUnread: boolean
  pagination: Pagination
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<NotificationList, ErrorResponse>>
  setFetchProps: (value?: NotificationFetchProps) => void
  handleMarkAllAsRead: () => Promise<void>
  handleMarkAsRead: (id: string) => Promise<void>
  handleMarkAsUnread: (id: string) => Promise<void>
  handleArchiveAll: () => Promise<void>
  handleArchive: (id: string) => Promise<void>
  handleUnarchive: (id: string) => Promise<void>
}

export const useFetchNotifications = ({
  programId,
  pagination: initialPagination,
  fetchProps: initialFetchProps = DefaultFetchProps,
}: IFetchNotificationsProps): FetchNotifications => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const { setToast } = useToast()
  const [pagination, setPagination] = useState<Pagination>(
    initialPagination || DefaultHalfMainPagination,
  )
  const [fetchProps, setFetchProps] = useLocalStorage<NotificationFetchProps>(
    'notifications-fetch-props',
    initialFetchProps,
  )
  const queryKeys = generateListKey({
    type: 'notification',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { ...fetchProps.filter, program_id: programId },
  })
  const {
    data,
    error,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<NotificationList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg = DefaultHalfMainPagination }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new NotificationsApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const resp: NotificationList = await requestHandler<NotificationList>(() =>
        client.findNotifications(
          {
            program_id: programId,
            limit: pagination.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
            is_archived:
              typeof fetchProps?.filter?.is_archived !== 'undefined'
                ? fetchProps?.filter.is_archived
                : undefined,
            is_read:
              typeof fetchProps?.filter?.is_read !== 'undefined'
                ? fetchProps?.filter.is_read
                : undefined,
            topic_group:
              fetchProps?.filter?.topic_group && fetchProps?.filter?.topic_group.length > 0
                ? fetchProps?.filter.topic_group
                : undefined,
          },
          { signal },
        ),
      )
      return resp
    },
    {
      getNextPageParam: (resp: NotificationList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...DefaultHalfMainPagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: NotificationList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...DefaultHalfMainPagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled: !!programId,
    },
  )
  const unreadQueryKeys = [
    ...generateBaseKey({
      type: 'notification',
    }),
    'unread',
  ]
  const { data: hasUnread = false } = useQuery<boolean, ErrorResponse>(
    unreadQueryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new NotificationsApi(generateApiConfig())
      const resp = await requestHandler<NotificationList>(() =>
        client.findNotifications(
          {
            program_id: programId,
            limit: 1,
            skip: undefined,
            order: undefined,
            sort: undefined,
            is_archived: undefined,
            is_read: false,
            topic_group:
              fetchProps?.filter?.topic_group && fetchProps?.filter?.topic_group.length > 0
                ? fetchProps?.filter.topic_group
                : undefined,
          },
          { signal },
        ),
      )
      return resp.total > 0
    },
    {
      enabled: !!programId,
    },
  )

  const handleMarkAllAsRead = async () => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<BatchNotificationOperation>(() =>
          client.markAllAsReadNotification({ program_id: programId }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const handleMarkAsRead = async (id: string) => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<Notification>(() =>
          client.markAsReadNotification({ program_id: programId, notification_id: id }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const handleMarkAsUnread = async (id: string) => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<Notification>(() =>
          client.markAsUnreadNotification({ program_id: programId, notification_id: id }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }
  const handleArchiveAll = async () => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<BatchNotificationOperation>(() =>
          client.archiveAllNotification({ program_id: programId }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const handleArchive = async (id: string) => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<Notification>(() =>
          client.archiveNotification({ program_id: programId, notification_id: id }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }
  const handleUnarchive = async (id: string) => {
    const client = new NotificationsApi(generateApiConfig())
    if (programId) {
      try {
        await requestHandler<Notification>(() =>
          client.unarchiveNotification({ program_id: programId, notification_id: id }),
        )
        await queryClient.invalidateQueries(
          generateBaseKey({
            type: 'notification',
          }),
        )
      } catch (e: any) {
        setToast?.({
          header: (translations as any)['error.default_toast_header'],
          message: e?.message || (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    } else {
      setToast?.({
        header: (translations as any)['error.default_toast_header'],
        message: (translations as any)['error.default_toast_message'],
        type: 'danger',
      })
    }
  }

  const _setFetchProps = (val?: NotificationFetchProps) => {
    setPagination(initialPagination || DefaultHalfMainPagination)
    return setFetchProps(val || DefaultFetchProps)
  }

  const resp = [...(data?.pages || [])].pop()

  return {
    data: (data?.pages || []).reduce<Notification[]>((list, p) => [...list, ...p.data], []) || [],
    error,
    fetchProps,
    isError,
    isLoading,
    isFetching: isFetching || isFetchingNextPage,
    hasUnread,
    hasNextPage,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    fetchNextPage,
    setFetchProps: _setFetchProps,
    handleMarkAllAsRead,
    handleMarkAsRead,
    handleMarkAsUnread,
    handleArchiveAll,
    handleArchive,
    handleUnarchive,
  }
}
