import { faInfo, faTools } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'

interface IProps {
  program: ExploreProgram
  onSelect: (value: 'citrus' | 'program') => void
}

export const SupportSelectStep = ({ program, onSelect: handleSelect }: IProps) => {
  return (
    <>
      <div className="pb-3">
        <button
          type="button"
          className="btn btn-link btn-outline-primary btn-block shadow-sm text-left"
          style={{
            borderRadius: '1rem',
          }}
          onClick={() => handleSelect('program')}
        >
          <div className="d-flex p-2">
            <FontAwesomeIcon icon={faInfo} className="my-auto mr-3" />
            <div>
              <div>General Inquiries</div>
              <div className="small">Reach out to the {program.name} team</div>
            </div>
          </div>
        </button>
      </div>
      <button
        type="button"
        className="btn btn-link btn-outline-primary btn-block shadow-sm text-left"
        style={{
          borderRadius: '1rem',
        }}
        onClick={() => handleSelect('citrus')}
      >
        <div className="d-flex p-2">
          <FontAwesomeIcon icon={faTools} className="my-auto mr-3" />
          <div>
            <div>Technical Support</div>
            <div className="small">Reach out to the Citrus team</div>
          </div>
        </div>
      </button>
    </>
  )
}
