import React from 'react'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  colProps?: React.HTMLAttributes<HTMLDivElement>
  animateProps?: React.HTMLAttributes<HTMLDivElement>
}

export const LoadingRow = ({ colProps, animateProps, ...props }: IProps) => (
  <div className="row" {...props}>
    <div className="col-12" {...colProps} style={{ height: '80px', ...colProps?.style }}>
      <div
        className="animate rounded"
        {...animateProps}
        style={{ height: '60px', ...animateProps }}
      />
    </div>
  </div>
)
