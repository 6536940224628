import { Options, Placement } from '@popperjs/core'
import { default as React, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from '../../hooks/usePopper'

interface IPortalProps {
  children: React.ReactNode
}

const Portal = ({ children }: IPortalProps) => {
  return createPortal(children, document.body)
}

interface IPopupProps extends React.HTMLAttributes<HTMLDivElement> {
  isShown: boolean
  toggle: () => void
}

export const TooltipPopup = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IPopupProps>>(
  function ForwardTooltipPopup({ children, isShown, toggle, ...props }, ref) {
    function handleResize() {
      if (isShown) {
        toggle()
      }
    }

    useEffect(() => {
      if (isShown) {
        window.addEventListener('resize', handleResize)
      } else {
        window.removeEventListener('resize', handleResize)
      }

      return () => {
        window.removeEventListener('resize', handleResize)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShown])

    return (
      <Portal>
        <div
          {...props}
          className="tooltip"
          role="tooltip"
          ref={ref}
          style={{
            ...props.style,
            ...(isShown ? {} : { display: 'none' }),
          }}
        >
          {children}
          <div className="arrow" data-popper-arrow></div>
        </div>
      </Portal>
    )
  },
)

interface IProps
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
    'title'
  > {
  title: string | React.ReactElement
  placement?: Placement
  popupProps?: React.HTMLAttributes<HTMLDivElement>
  popperOptions?: Partial<Options>
}

export const Tooltip = ({
  title,
  placement = 'right',
  children,
  popupProps,
  popperOptions,
  ...props
}: React.PropsWithChildren<IProps>) => {
  const { target, toggle, ...tooltipProps } = usePopper<HTMLButtonElement>({
    placement,
    modifiers: [],
    showOnHover: true,
    ...popperOptions,
  })
  if (!title) {
    return <>{children}</>
  }
  return (
    <>
      <span ref={target} {...props}>
        {children}
      </span>
      <TooltipPopup toggle={toggle} {...{ ...tooltipProps, ...popupProps }}>
        {title}
      </TooltipPopup>
    </>
  )
}
