import { faArrowLeft, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { ExploreProgram } from '@citruscamps/citrus-client'
import { useSupport, ISupportParams } from '../../hooks/useSupport'
import { getBrandColor, getComplementaryColor } from '../../utils/branding-utils'
import { FormSkeleton } from '../FormSkeleton/FormSkeleton'
import { SupportMessageStep } from './SupportMessageStep'
import { SupportMessageSuccess } from './SupportMessageSuccess'
import { SupportSelectStep } from './SupportSelectStep'

interface IProps {
  program: ExploreProgram
}

export const SupportMenu = ({ program }: IProps) => {
  const {
    customer,
    customerProfile,
    error,
    isError,
    isLoadingCustomer,
    isShown,
    message,
    handleSubmit,
    setIsShown,
    setMessage,
  } = useSupport()
  const complementaryColor = getComplementaryColor(program?.preferences)
  const safeBrandColor = getBrandColor(program?.preferences)
  const [step, setStep] = useState<number>(0)

  const previousStep = (): void => {
    if (step > 0) {
      setStep(step - 1)
    }
  }

  const nextStep = (): void => {
    if (step < steps.length - 1) {
      setStep(step + 1)
    }
  }

  const steps = [
    <SupportSelectStep
      key="support-message-type"
      program={program}
      onSelect={(value: 'citrus' | 'program') => {
        setMessage((m) => ({ ...m, type: value }))
        nextStep()
      }}
    />,
    <SupportMessageStep
      key="support-message-step"
      initialValues={message}
      customer={customer}
      customerProfile={customerProfile}
      program={program}
      onSubmit={async (value: ISupportParams) => {
        setMessage(value)
        try {
          await handleSubmit({
            ...message,
            subject: value.subject,
            body: value.body,
            new_customer_profile: value.new_customer_profile,
            context: value.context,
          })
          nextStep()
          setMessage({
            type: value.type,
            program_id: program?.id || '',
            medium: 'email',
            subject: '',
            body: '',
            context: undefined,
          })
        } catch {
          nextStep()
          setMessage(value)
        }
      }}
    />,
    <SupportMessageSuccess
      key="support-message-step"
      isError={isError}
      error={error}
      programName={message.type === 'program' ? program.name : 'Citrus'}
    />,
  ]
  if (step > steps.length - 1) {
    setStep(0)
  }
  const currentStep: React.ReactElement = steps[step]

  if (!isShown) {
    return null
  }
  return (
    <div className="custom-support">
      <div className="support-form shadow-md border border-primary">
        <div
          className="pt-1 pb-2 px-3"
          style={{
            backgroundColor: safeBrandColor,
            color: complementaryColor,
            borderRadius: '1rem 1rem 0 0',
          }}
        >
          <div className="d-flex justify-content-between">
            {step > 0 ? (
              <button className="btn btn-link" onClick={previousStep}>
                <FontAwesomeIcon icon={faArrowLeft} fixedWidth={true} color={complementaryColor} />
              </button>
            ) : (
              <div style={{ padding: '0.375rem 0.75rem' }}>
                <div style={{ width: '1.25em' }}>&nbsp;</div>
              </div>
            )}
            <h6 style={{ padding: '0.75rem' }} className="m-0 text-center">
              Contact {message.type === 'program' ? program.name : 'Citrus'}
            </h6>
            <button
              className="btn btn-link"
              onClick={() => {
                setIsShown(false)
                setStep(0)
                setMessage({
                  type: 'program',
                  program_id: program?.id || '',
                  medium: 'email',
                  subject: '',
                  body: '',
                  context: [],
                })
              }}
            >
              <FontAwesomeIcon icon={faTimes} color={complementaryColor} />
            </button>
          </div>
        </div>
        <div className="p-3">{isLoadingCustomer ? <FormSkeleton /> : currentStep}</div>
      </div>
    </div>
  )
}
