import {
  CustomerApi,
  CustomerOrderListV2,
  CustomerOrderV2,
  ErrorResponse,
  OrderStatus,
  PaymentMethod,
} from '@citruscamps/citrus-client'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { Dispatch, useState } from 'react'
import {
  DefaultDozenPagination,
  DefaultMainPagination,
  UnlimitedPagination,
} from '../constants/pagination'
import { Pagination } from '../interfaces/pagination'
import translations from '../translations/en.json'
import { generateApiConfig } from '../utils/client-config'
import { generateBaseKey, generateItemKey, generateListKey } from '../utils/key-generator'
import { useRequestHandler } from './useRequestHandler'
import { useSearchValue } from './useSearchValue'
import { useToast } from './useToast'
import { useTrackInsightEvent } from './useTrackInsightEvent'

const DefaultFetchProps: OrderFetchProps = {
  sort: 'updated',
  order: 'DESC',
  filter: {
    status: [],
    checkout_method: [],
  },
}

export interface OrderFilterProps {
  status?: OrderStatus[]
  checkout_method?: PaymentMethod[]
}

export interface OrderFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter: OrderFilterProps
}

interface IProps {
  programId?: string
  pagination?: Pagination
  fetchProps?: OrderFetchProps
  enabled?: boolean
  fetchAll?: boolean
  overwriteFilters?: boolean
}

interface FetchOrders {
  data: CustomerOrderV2[]
  error?: ErrorResponse
  fetchProps: OrderFetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  search: string
  isSearching: boolean
  handleCancel: (id: string) => Promise<void>
  setFetchProps: (value?: OrderFetchProps) => void
  setPagination: Dispatch<Pagination>
  setSearch: Dispatch<string>
}

export const useFetchOrdersV2 = ({
  programId,
  pagination: initialPagination = DefaultMainPagination,
  overwriteFilters,
  enabled = true,
  fetchProps: initialFetchProps = DefaultFetchProps,
  ...props
}: IProps): FetchOrders => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const { handleTrack } = useTrackInsightEvent()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [fetchProps, setFetchProps] = useState<OrderFetchProps>(initialFetchProps)
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {
    onDefer: () => setPagination(initialPagination),
  })
  const queryKeys = generateListKey({
    type: 'order',
    pagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId, ...fetchProps.filter },
    search: deferSearch,
  })
  const {
    isInitialLoading: isLoading,
    isError,
    data: resp,
    error,
  } = useQuery<CustomerOrderListV2, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new CustomerApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response: CustomerOrderListV2 = await requestHandler<CustomerOrderListV2>(
        () =>
          client.findCustomerOrdersV2(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order || undefined,
              sort: fetchProps.sort || undefined,
              search: deferSearch || undefined,
              program_id: programId,
              status:
                fetchProps?.filter?.status && fetchProps?.filter?.status.length > 0
                  ? fetchProps?.filter.status
                  : undefined,
              checkout_method:
                fetchProps?.filter?.checkout_method &&
                fetchProps?.filter?.checkout_method.length > 0
                  ? fetchProps?.filter.checkout_method
                  : undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'order',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    { enabled: !!programId && enabled },
  )

  const handleCancel = async (id: string): Promise<void> => {
    if (id) {
      const orderClient = new CustomerApi(generateApiConfig())
      try {
        const order = await requestHandler<CustomerOrderV2>(
          () => orderClient.cancelCustomerOrderV2({ order_id: id }),
          {
            toast: {
              setToast,
              error: (translations as any)['error.default_toast_message'],
            },
          },
        )
        await Promise.allSettled(
          order.items.map((item) =>
            handleTrack({
              type: 'remove_from_cart',
              props: {
                order_id: id,
                order_amount: order?.amount_total.value,
                order_item_id: item.id,
                order_item_amount: item.amount.value,
                currency: order?.currency,
              },
            }),
          ),
        )
        await queryClient.invalidateQueries(generateBaseKey({ type: 'order' }))
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    }
  }

  const _setFetchProps = (val?: OrderFetchProps) => {
    setPagination(initialPagination)
    return setFetchProps(val || DefaultFetchProps)
  }

  return {
    data: resp?.data || [],
    error: error || undefined,
    fetchProps,
    isError,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    search,
    isSearching,
    handleCancel,
    setFetchProps: _setFetchProps,
    setPagination,
    setSearch,
  }
}

interface FetchInfiniteOrders extends Omit<FetchOrders, 'setPagination'> {
  isFetching: boolean
  isFetchingNextPage: boolean
  hasNextPage: boolean
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<CustomerOrderListV2, ErrorResponse>>
}

export const useFetchInfiniteOrdersV2 = ({
  programId,
  pagination = DefaultDozenPagination,
  enabled = true,
  fetchAll = false,
  fetchProps: initialFetchProps = DefaultFetchProps,
}: IProps): FetchInfiniteOrders => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const queryClient = useQueryClient()
  const { handleTrack } = useTrackInsightEvent()
  const [fetchProps, setFetchProps] = useState<OrderFetchProps>(initialFetchProps)
  const { search, deferSearch, setSearch, isSearching } = useSearchValue('', {})
  const queryKeys = generateListKey({
    type: 'order',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId, ...fetchProps.filter },
    search: deferSearch,
  })
  const {
    data: resp,
    error,
    isError,
    isInitialLoading: isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<CustomerOrderListV2, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new CustomerApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const response = await requestHandler<CustomerOrderListV2>(
        () =>
          client.findCustomerOrdersV2(
            {
              limit: pagination.limit,
              skip,
              order: fetchProps.order || undefined,
              sort: fetchProps.sort || undefined,
              search: deferSearch || undefined,
              program_id: programId,
              status:
                fetchProps?.filter?.status && fetchProps?.filter?.status.length > 0
                  ? fetchProps?.filter.status
                  : undefined,
              checkout_method:
                fetchProps?.filter?.checkout_method &&
                fetchProps?.filter?.checkout_method.length > 0
                  ? fetchProps?.filter.checkout_method
                  : undefined,
            },
            { signal },
          ),
        {
          toast: { setToast },
        },
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'order',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    {
      getNextPageParam: (resp: CustomerOrderListV2): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: CustomerOrderListV2): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled,
    },
  )

  const handleCancel = async (id: string): Promise<void> => {
    if (id) {
      const orderClient = new CustomerApi(generateApiConfig())
      try {
        const order = await requestHandler<CustomerOrderV2>(
          () => orderClient.cancelCustomerOrderV2({ order_id: id }),
          {
            toast: {
              setToast,
              error: (translations as any)['error.default_toast_message'],
            },
          },
        )
        await Promise.allSettled(
          order.items.map((item) =>
            handleTrack({
              type: 'remove_from_cart',
              props: {
                order_id: id,
                order_amount: order?.amount_total.value,
                order_item_id: item.id,
                order_item_amount: item.amount.value,
                currency: order?.currency,
              },
            }),
          ),
        )
        await queryClient.invalidateQueries(generateBaseKey({ type: 'order' }))
      } catch (e: any) {
        setToast({
          header: (translations as any)['error.default_toast_header'],
          message: (translations as any)['error.default_toast_message'],
          type: 'danger',
        })
        throw e
      }
    }
  }

  const _setFetchProps = (val?: OrderFetchProps) => {
    return setFetchProps(val || DefaultFetchProps)
  }

  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }

  const response = [...(resp?.pages || [])].pop()

  return {
    data:
      (resp?.pages || []).reduce<CustomerOrderV2[]>((list, p) => [...list, ...p.data], []) || [],
    error: error || undefined,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading: isLoading || isSearching,
    pagination: {
      ...pagination,
      count: response?.count || pagination.count,
      total: response?.total || pagination.total,
      page: response?.page || pagination.page,
      page_count: response?.page_count || pagination.page_count,
    },
    search,
    isSearching,
    handleCancel,
    fetchNextPage,
    setFetchProps: _setFetchProps,
    setSearch,
  }
}
