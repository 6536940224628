import isEmail from 'validator/lib/isEmail'
import * as Yup from 'yup'
import translations from '../../../translations/en.json'

interface IGetSchema {
  hasProfile?: boolean
}

export class SupportSchema {
  static get({ hasProfile = false }: IGetSchema = {}) {
    return Yup.object().shape({
      subject: Yup.string().required((translations as any)['error.support_type_required']),
      issue_type: Yup.string()
        .notRequired()
        .when('type', {
          is: 'citrus',
          then: Yup.string().required((translations as any)['error.issue_type_required']),
        }),
      body: Yup.string().required((translations as any)['error.message_body_required']),
      new_customer_profile: Yup.object({
        first_name: Yup.string()
          .test('required', (translations as any)['error.first_name_required'], function (val) {
            return hasProfile || !!val
          })
          .max(40, (translations as any)['error.first_name_maxlength']),
        last_name: Yup.string()
          .test('required', (translations as any)['error.last_name_required'], function (val) {
            return hasProfile || !!val
          })
          .max(40, (translations as any)['error.last_name_maxlength']),
        email: Yup.string()
          .test('required', (translations as any)['error.email_required'], function (val) {
            return hasProfile || !!val
          })
          .test('email', (translations as any)['error.email_invalid'], function (val) {
            return hasProfile || isEmail(val || '')
          }),
      }),
    })
  }
}
