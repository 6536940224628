import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ErrorResponse } from '@citruscamps/citrus-client'

interface IProps {
  isError: boolean
  error: ErrorResponse | null
  programName: string
}

export const SupportMessageSuccess = ({ isError, programName }: IProps) => {
  if (isError) {
    return (
      <div className="d-flex justify-content-center my-3">
        <div className="h-100 my-auto text-center">
          <h4>Something went wrong</h4>
          <div>We have not sent any messages to {programName}. Please try again.</div>
        </div>
      </div>
    )
  }
  return (
    <div className="text-center my-3">
      <FontAwesomeIcon icon={faCheckCircle} size="3x" className="mb-3 text-success" />
      <h5>Message Successfully Sent</h5>
      <p className="text-muted">
        Thank you for contacting us. We have received your message and will be in touch shortly.
      </p>
    </div>
  )
}
