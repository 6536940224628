import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { ErrorResponse, ExploreProgram } from '@citruscamps/citrus-client'
import { ROUTES } from '../../constants/routes'
import { UndrawSorry } from './components/UndrawSorry'

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string
  header?: string
  description?: string | JSX.Element
  error?: ErrorResponse | null
  stack?: string
  size?: 'lg' | 'md' | 'sm' | 'xs'
  hideButton?: boolean
  align?: 'left' | 'center' | 'right'
  program?: ExploreProgram
}

export const ErrorContent = ({
  header = 'Somethings missing',
  description = 'Looks like we had some trouble loading what you were looking for. Try going back to the previous page or try contacting our support for assistance.',
  error,
  stack,
  children,
  size = 'sm',
  hideButton = false,
  align = 'center',
  program,
  ...props
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const router = useRouter()
  const [isDevCount, setIsDevCount] = useState<number>(0)
  if (error?.code && error?.code >= 500) {
    header = 'Well isn’t this embarrassing?'
    description = error.message || description
  } else if (error?.code && error?.code === 404) {
    header = 'Are you lost?'
    description =
      error.message ||
      'Looks like the page you are looking for is missing. Try going back to the previous page or try contacting our support for assistance.'
  } else if (error?.code && (error?.code === 401 || error?.code === 403)) {
    header = 'Are you lost?'
    description =
      error.message ||
      'Looks like you are missing permissions to view this. Try logging out and logging into the correct account or try contacting our support for assistance..'
  }
  let colSize: string = 'col'
  switch (size) {
    case 'lg':
      colSize = 'col'
      break
    case 'md':
      colSize = 'col-sm-8 col-md-6'
      break
    case 'sm':
      colSize = 'col-10 col-md-8 col-lg-6'
      break
    default:
      break
  }
  const alignClassName =
    align === 'left'
      ? 'justify-content-start text-left'
      : align === 'center'
      ? 'justify-content-center text-center'
      : 'justify-content-end text-right'

  return (
    <div {...props} className={['row', alignClassName, props.className].filter(Boolean).join(' ')}>
      <div className={colSize}>
        {size !== 'xs' && (
          <UndrawSorry
            color={program?.preferences?.brand_accent_color}
            className="img-fluid px-5"
            onClick={() => setIsDevCount((c) => c + 1)}
          />
        )}
        <div className="img-fluid">
          {size !== 'xs' && <h4 className="mt-3">{header}</h4>}
          <div className="mb-4">{description}</div>
          {isDevCount > 8 && (
            <div>
              <pre className="pre-scrollable small mb-3" style={{ overflow: 'scroll' }}>
                {stack || 'No error stack'}
              </pre>
            </div>
          )}
          {!hideButton &&
            (children ||
              (program?.id ? (
                <Link
                  href={{
                    pathname: ROUTES.EXPLORE,
                    query: { program_id: program?.id },
                  }}
                  className="btn btn-primary"
                  passHref
                >
                  Go back home
                </Link>
              ) : (
                <button type="button" className="btn btn-primary" onClick={() => router.back()}>
                  Go back
                </button>
              )))}
        </div>
      </div>
    </div>
  )
}
