import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { faQuestion } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Head from 'next/head'
import React from 'react'
import 'styled-components'
import { DefaultTheme, ThemeProvider as TP, ThemeProviderProps } from 'styled-components'
import { ExploreEvent, ExplorePreferences, ExploreProgram } from '@citruscamps/citrus-client'
import { ROUTES } from '../../constants/routes'
import { useCitrusRouter } from '../../hooks/useCitrusRouter'
import { useSupport } from '../../hooks/useSupport'
import { useToast } from '../../hooks/useToast'
import {
  DefaultBrandAccentColor,
  getBrandColor,
  getComplementaryColor,
  lightOrDark,
} from '../../utils/branding-utils'
import { toQueryParams } from '../../utils/query-params'
import { toPageTitle } from '../../utils/to-page-title'
import { FeatureFlag } from '../FeatureFlag/FeatureFlag'
import { Footer } from '../Footer/Footer'
import { Meta } from '../Meta/Meta'
import { SupportMenu } from '../SupportMenu/SupportMenu'
import { Toast } from '../Toast/Toast'

declare module 'styled-components' {
  export interface DefaultTheme extends Partial<ExplorePreferences> {
    brand_accent_color: string
  }
}

const ThemeProvider: React.FC<ThemeProviderProps<DefaultTheme>> = TP as any

config.autoAddCss = false

interface IProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  authContainer?: boolean
  description?: string
  event?: ExploreEvent
  hasFooterSpacer?: boolean
  hostname?: string
  image?: string
  keywords?: string[]
  program?: ExploreProgram
  title?: string
  theme?: 'dark' | 'light'
}

export const Layout = ({
  authContainer,
  children,
  description,
  event,
  hasFooterSpacer,
  image,
  keywords,
  program,
  title,
  hostname,
  theme: themeOverride,
  ...props
}: React.PropsWithChildren<IProps>) => {
  const router = useCitrusRouter()
  const { preferences } = program || {
    preferences: {
      brand_accent_color: DefaultBrandAccentColor,
    },
  }
  const { toast } = useToast()
  const { isShown: isShownSupport, setIsShown: setIsShownSupport } = useSupport()
  const q = toQueryParams({
    program_id: program?.id,
    brand_accent_color: preferences?.brand_accent_color,
  })
  const safeBrandColor = getBrandColor(program?.preferences)
  const theme = themeOverride || lightOrDark(getBrandColor(preferences))
  const complementaryColor = getComplementaryColor(program?.preferences)

  return (
    <div className="layout" {...props}>
      <ThemeProvider theme={preferences as DefaultTheme}>
        <Meta
          themeColor={preferences?.brand_accent_color}
          title={[toPageTitle(program), typeof title === 'undefined' ? 'Loading' : title]
            .filter((t) => !!t)
            .join(' · ')}
          keywords={keywords}
          event={event}
          url={router.pathname}
          image={image || program?.preferences?.icon || program?.preferences?.logo}
          description={
            description ||
            (program
              ? `${program?.name} is using Citrus to organize upcoming events. Check out ${program?.name}'s events, learn more, or contact this organizer.`
              : undefined)
          }
          siteName={[program?.name, 'Citrus'].filter(Boolean).join(' · ')}
          website={
            program && (hostname || router.hostname)
              ? program?.website ||
                `${hostname || router.hostname}${ROUTES.EXPLORE.replace(
                  '[program_id]',
                  program.id,
                )}`
              : undefined
          }
        />
        <Head>
          <link href={`/api/styles/branding.css?${q}`} rel="stylesheet" />
        </Head>
        {toast ? <Toast {...toast} /> : null}
        {authContainer ? (
          <div className="auth-container custom-branding">
            {isShownSupport && !!program && (
              <FeatureFlag flagKey="communications_enabled">
                <SupportMenu program={program} />
              </FeatureFlag>
            )}
            <div className="auth-content my-auto">
              {children}
              <Footer program={program} theme={theme} />
            </div>
          </div>
        ) : (
          <div className="custom-branding">
            {!!program &&
              (isShownSupport ? (
                <FeatureFlag flagKey="communications_enabled">
                  <SupportMenu program={program} />
                </FeatureFlag>
              ) : (
                <div className="custom-support">
                  <button
                    type="button"
                    style={{ backgroundColor: safeBrandColor }}
                    className="btn shadow-sm btn-support"
                    onClick={() => setIsShownSupport(true)}
                  >
                    <FontAwesomeIcon
                      icon={faQuestion}
                      size="2x"
                      transform="shrink-3"
                      color={complementaryColor}
                    />
                  </button>
                </div>
              ))}
            <main>{children}</main>
            <Footer program={program} theme="light" />
            {hasFooterSpacer ? <div className="py-5 d-block d-sm-none"></div> : null}
          </div>
        )}
      </ThemeProvider>
    </div>
  )
}
