import { CustomerOrder, ExploreProgram } from '@citruscamps/citrus-client'
import { faShoppingBasket as fadShoppingBasket } from '@fortawesome/pro-duotone-svg-icons'
import {
  faCircle,
  faFileInvoice,
  faShoppingBasket,
  faSpinner,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useMemo } from 'react'
import { ROUTES } from '../../../constants/routes'
import { useFetchInfiniteOrders } from '../../../hooks/useFetchOrders'
import { usePopper } from '../../../hooks/usePopper'
import { useTrackInsightEvent } from '../../../hooks/useTrackInsightEvent'
import { LoadingButton } from '../../LoadingButton/LoadingButton'
import { Popover } from '../../Popover/Popover'
import { TruncatedText } from '../../TruncatedText/TruncatedText'

interface IProps {
  program?: ExploreProgram
  theme: 'light' | 'dark'
}

/**
 * @deprecated TODO: Product financial foundation is not implemented yet
 * ⚠️ This will be removed in the future when we migrate to products
 **/
export const CheckoutDropdown = ({ program }: IProps) => {
  const { target, toggle, ...tooltipProps } = usePopper<HTMLButtonElement>({
    placement: 'bottom-end',
  })
  const { handleTrack } = useTrackInsightEvent()

  const { data: orders, handleCancel } = useFetchInfiniteOrders({
    programId: program?.id,
    fetchProps: {
      sort: 'created',
      order: 'DESC',
      filter: {
        status: ['created'],
      },
    },
    fetchAll: true,
  })
  const pendingOrders: CustomerOrder[] = useMemo(() => {
    const values = orders.filter((o) => o.items.length > 0)
    if (values.length > 0) {
      return values
    }
    return []
  }, [orders])

  return (
    <div className="nav-item px-3 px-md-1">
      <button
        ref={target}
        className="btn btn-link nav-link d-none d-md-inline-block"
        onClick={async () => {
          toggle()
          if (!tooltipProps.isShown) {
            await Promise.allSettled([
              handleTrack({
                type: 'view_cart',
                props: {},
              }),
            ])
          }
        }}
      >
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faShoppingBasket} />
          {pendingOrders.length > 0 && (
            <FontAwesomeIcon
              icon={faCircle}
              className="text-danger"
              transform="shrink-8 down-6 right-6"
            />
          )}
        </span>
      </button>
      <Popover
        {...tooltipProps}
        toggle={toggle}
        style={{ maxHeight: '82vh', overflowY: 'scroll', minWidth: 260 }}
      >
        {pendingOrders.length > 0 ? (
          pendingOrders.map((order) => (
            <Link
              key={`checkout.${order.id}`}
              href={{
                pathname: ROUTES.CHECKOUT,
                query: { order_id: order.id, program_id: program?.id },
              }}
              className="dropdown-item border-bottom text-decoration-none d-flex row no-gutters pl-3"
              passHref
            >
              <div className="col h-100 my-auto">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon fixedWidth={true} icon={faFileInvoice} />
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="text-danger"
                    transform="shrink-8 down-6 right-6"
                  />
                </span>
              </div>
              <div className="col-9">
                <TruncatedText style={{ marginLeft: '-0.5rem' }}>
                  <TruncatedText className="font-weight-bold text-decoration-none">
                    {order.items.map((i) => i.description).join(', ') || 'Unknown Item'}
                  </TruncatedText>
                  <TruncatedText className="text-decoration-none text-muted">
                    {order.amount_total.display}
                  </TruncatedText>
                  <div className="small">Continue to checkout</div>
                </TruncatedText>
              </div>
              <div className="col-1 h-100 my-auto">
                {order.status === 'created' && (
                  <LoadingButton
                    type="button"
                    className="btn btn-link text-dark"
                    onClick={async (evt) => {
                      evt?.preventDefault()
                      await handleCancel(order.id)
                    }}
                    loadingIconProps={{ icon: faSpinner, className: 'mr-0' }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </LoadingButton>
                )}
              </div>
            </Link>
          ))
        ) : (
          <div className="dropdown-item text-center">
            <FontAwesomeIcon className="mr-2" icon={fadShoppingBasket} fixedWidth={true} />
            <span>There is nothing in your cart.</span>
          </div>
        )}
      </Popover>
    </div>
  )
}
