import { useFormik } from 'formik'
import React, { ChangeEvent, useEffect } from 'react'
import {
  ConversationContext,
  Customer,
  CustomerProfile,
  ExploreProgram,
} from '@citruscamps/citrus-client'
import { ISupportParams } from '../../hooks/useSupport'
import { useFlags } from '../FeatureFlag/hooks/useFlags'
import { LoadingButton } from '../LoadingButton/LoadingButton'
import { SupportSchema } from './schemas/SupportSchema'
import { formatFullName } from '../../utils/formatters'

interface IProps {
  initialValues: ISupportParams
  customer: Customer | undefined
  customerProfile: CustomerProfile | undefined
  program: ExploreProgram
  onSubmit: (value: ISupportParams) => void
}

export const SupportMessageStep = ({
  initialValues,
  customer,
  customerProfile,
  program,
  onSubmit,
}: IProps) => {
  const { flags } = useFlags()
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    isSubmitting,
    setFieldError,
  } = useFormik<ISupportParams>({
    initialValues: {
      type: initialValues.type,
      issue_type: initialValues.issue_type,
      order_id: initialValues.order_id,
      registration_id: initialValues.registration_id,
      program_id: initialValues.program_id,
      medium: initialValues.medium,
      subject: initialValues.subject,
      body: initialValues.body,
      new_customer_profile: {
        program_id: program.id,
        customer_id: customer?.id || initialValues.new_customer_profile?.customer_id || '',
        email: customer?.email || initialValues.new_customer_profile?.email || '',
        first_name: customer?.first_name || initialValues.new_customer_profile?.first_name || '',
        last_name: customer?.last_name || initialValues.new_customer_profile?.last_name || '',
      },
      context: initialValues.context || [],
    },
    validationSchema: SupportSchema.get({ hasProfile: !!customerProfile }),
    onSubmit: async (value: ISupportParams, { setSubmitting }) => {
      try {
        const subject: string = customerProfile
          ? `${value.subject} from ${formatFullName(customerProfile)}`
          : value.subject
        await onSubmit({ ...value, subject })
      } catch (err) {
        const e: any = err || {}
        if (e.param) {
          setFieldError(e.param, e.message)
        } else if (e.message) {
          setFieldError('rating', e.message)
        }
      }
      setSubmitting(false)
    },
  })

  useEffect(() => {
    if (values.type !== initialValues.type) {
      setFieldValue('type', initialValues.type, true)
      setFieldValue('subject', '', true)
    }
  }, [initialValues.type, setFieldValue, values.type])

  const issueTypes: Array<{ value: string; label: string }> = [
    { value: 'tech_support', label: 'Technical Support' },
    { value: 'suggest_feature', label: 'Suggest a new feature / improvement' },
    { value: 'bug', label: 'Report a bug' },
  ]
  let subjects: Array<{ value: string; label: string; context?: ConversationContext[] }> = []
  let subjectLabel: string = !values.subject.includes('Inquiry')
    ? 'Let us know how we can help'
    : 'Let us know about your experience'
  if (values.type === 'program') {
    subjects.push(
      { value: 'Confirm order or payment', label: 'Confirm order or payment', context: ['orders'] },
      {
        value: 'Refund or cancellation request',
        label: 'Refund or cancellation',
        context: ['cancellation'],
      },
      { value: 'Event inquiry', label: 'Question about event', context: ['inquiry'] },
      {
        value: 'Registration inquiry',
        label: 'Question about registration',
        context: ['inquiry'],
      },
      { value: 'Account inquiry', label: 'Account inquiry', context: ['inquiry'] },
    )
    if (flags?.memberships_enabled) {
      subjects.push({
        value: 'Membership inquiry',
        label: 'Question about my membership',
        context: ['memberships'],
      })
    }
    if (flags?.sessions_enabled) {
      subjects.push({
        value: 'Session credit inquiry',
        label: 'Session credit balance',
        context: ['sessions', 'inquiry'],
      })
    }
  } else if (values.type === 'citrus') {
    switch (values.issue_type) {
      case 'bug':
        subjectLabel = 'Tell us more about any problems you found'
        subjects.push(
          { value: 'Performance issues', label: 'Performance issues' },
          { value: 'Event issues', label: 'Issues with event' },
          { value: 'Registration issues', label: 'Issues with registration' },
          { value: 'Account issues', label: 'Account issues' },
        )
        break
      case 'suggest_feature':
        subjectLabel = 'Let us know your idea for an improvement'
        subjects.push(
          { value: 'Performance improvements', label: 'Performance improvements' },
          { value: 'Event improvements', label: 'Improvements with event' },
          { value: 'Registration improvements', label: 'Improvements with registration' },
          { value: 'Account improvements', label: 'Account improvements' },
        )
        break
      case 'tech_support':
        subjectLabel = 'Tell us more to help you resolve any issues'
      // eslint-disable-next-line no-fallthrough
      default:
        subjects.push(
          { value: 'Look and feel', label: 'Look and feel' },
          { value: 'Performance inquiry', label: 'Performance' },
          { value: 'Event inquiry', label: 'Question about event' },
          { value: 'Registration inquiry', label: 'Question about registration' },
          { value: 'Account inquiry', label: 'Account inquiry' },
        )
        break
    }
  }
  subjects.push({ value: 'General inquiry', label: 'General inquiries' })

  return (
    <form id="support-message-step-form" onSubmit={handleSubmit}>
      {!customerProfile && (
        <>
          <div className="form-group">
            <label className="form-label">Your first name</label>
            <input
              id="new_customer_profile.first_name"
              name="new_customer_profile.first_name"
              className={
                'form-control' +
                ((touched?.new_customer_profile as any)?.first_name &&
                !!(errors?.new_customer_profile as any)?.first_name
                  ? ' is-invalid'
                  : '')
              }
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_customer_profile?.first_name}
              autoFocus
            />
            <div className="invalid-feedback d-block">
              {(touched?.new_customer_profile as any)?.first_name &&
              !!(errors.new_customer_profile as any)?.first_name
                ? (errors.new_customer_profile as any)?.first_name
                : '\u00A0'}
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">Your last name</label>
            <input
              id="new_customer_profile.last_name"
              name="new_customer_profile.last_name"
              className={
                'form-control' +
                ((touched?.new_customer_profile as any)?.last_name &&
                !!(errors?.new_customer_profile as any)?.last_name
                  ? ' is-invalid'
                  : '')
              }
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_customer_profile?.last_name}
            />
            <div className="invalid-feedback d-block">
              {(touched?.new_customer_profile as any)?.last_name &&
              !!(errors.new_customer_profile as any)?.last_name
                ? (errors.new_customer_profile as any)?.last_name
                : '\u00A0'}
            </div>
          </div>
          <div className="form-group">
            <label className="form-label">Your email address</label>
            <input
              id="new_customer_profile.email"
              name="new_customer_profile.email"
              className={
                'form-control' +
                ((touched?.new_customer_profile as any)?.email &&
                !!(errors?.new_customer_profile as any)?.email
                  ? ' is-invalid'
                  : '')
              }
              type="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.new_customer_profile?.email}
            />
            <div className="invalid-feedback d-block">
              {(touched?.new_customer_profile as any)?.email &&
              !!(errors.new_customer_profile as any)?.email
                ? (errors.new_customer_profile as any)?.email
                : '\u00A0'}
            </div>
          </div>
        </>
      )}
      {values.type === 'citrus' && (
        <div className="form-group">
          <label className="form-label">What can we help with?</label>
          <select
            name="issue_type"
            className={
              'form-control' + (touched?.issue_type && !!errors?.issue_type ? ' is-invalid' : '')
            }
            value={values.issue_type}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            <option value="" className="text-muted">
              Select a support type
            </option>
            {issueTypes.map((t) => (
              <option key={t.value} value={t.value}>
                {t.label}
              </option>
            ))}
          </select>
          <div className="invalid-feedback d-block pb-2">
            {touched?.issue_type && !!errors?.issue_type ? errors?.issue_type : '\u00A0'}
          </div>
        </div>
      )}
      <div className="form-group">
        {values.type !== 'citrus' && <label className="form-label">What can we help with?</label>}
        <select
          name="subject"
          className={'form-control' + (touched?.subject && !!errors?.subject ? ' is-invalid' : '')}
          value={values.subject}
          onBlur={handleBlur}
          onChange={(e) => {
            const subj = subjects.find((s) => s.value === e.target?.value)
            if (subj?.context) {
              setFieldValue('context', subj.context, true)
            }
            handleChange(e)
          }}
        >
          <option value="" className="text-muted">
            Select a topic
          </option>
          {subjects.map((s) => (
            <option key={s.value} value={s.value}>
              {s.label}
            </option>
          ))}
        </select>
        <div className="invalid-feedback d-block pb-2">
          {touched?.subject && !!errors?.subject ? errors?.subject : '\u00A0'}
        </div>
      </div>
      <div className="form-group">
        <label className="form-label">{subjectLabel}</label>
        <textarea
          name="body"
          className={'form-control' + (touched?.body && !!errors?.body ? ' is-invalid' : '')}
          style={{ borderRadius: '1rem' }}
          value={values.body}
          onChange={handleChange}
          onBlur={handleBlur}
        ></textarea>
        <div className="invalid-feedback d-block">
          {touched?.body && !!errors?.body ? errors?.body : '\u00A0'}
        </div>
      </div>
      {customer && !customerProfile && (
        <div className="custom-control custom-checkbox custom-checkbox-sm">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`customerCreate`}
            name={`customerCreate`}
            checked={!!values.new_customer_profile?.customer_id}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              const checked = evt.target.checked
              if (!checked) {
                setFieldValue('new_customer_profile.customer_id', undefined, true)
              } else {
                setFieldValue('new_customer_profile.customer_id', customer.id, true)
              }
            }}
          />
          <label className="custom-control-label small text-muted" htmlFor={`customerCreate`}>
            Allow {program.name} and Citrus to use your information in accordance with their
            respective terms of service and privacy policies.
          </label>
        </div>
      )}
      <div className="ml-auto py-3 d-flex justify-content-end">
        <LoadingButton
          className="btn btn-primary btn-block d-md-inline w-md-auto"
          type="submit"
          loading={isSubmitting}
        >
          Send message
        </LoadingButton>
      </div>
    </form>
  )
}
