import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { CustomerProfile, ExploreProgram } from '@citruscamps/citrus-client'
import { ROUTES } from '../../constants/routes'
import { AccountButton } from './AccountButton'

interface IProps {
  customer?: CustomerProfile
  program: ExploreProgram
  theme: 'light' | 'dark'
  loading?: boolean
}

export const UserDropdown = ({ customer, program, loading, theme }: IProps): React.ReactElement => {
  const router = useRouter()
  const query = { goto: router.asPath, program_id: program?.id }

  return (
    <div className="nav-item px-3 px-md-1 user-dropdown">
      {loading ? (
        <div className="nav-link">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin input-icon position-relative d-inline-block"
          />
        </div>
      ) : !customer ? (
        <Link href={{ pathname: ROUTES.LOGIN, query }} className="nav-link" passHref>
          Sign in<span className="d-none d-md-inline"> / Sign up</span>
        </Link>
      ) : (
        <AccountButton customer={customer} program={program} theme={theme} />
      )}
    </div>
  )
}
