import React from 'react'
import { Pagination } from '../../interfaces/pagination'
import { LoadingRow } from './LoadingRow'

interface IProps extends Partial<Pagination> {}
export const LoadingRows = ({ limit = 3, children }: React.PropsWithChildren<IProps> = {}) => (
  <>
    {Array.from({ length: limit })
      .fill(null)
      .map((_, i) => `loading-row-${i}`)
      .map((key) => (
        <div key={key}>
          <hr className="my-3" />
          {children || <LoadingRow />}
        </div>
      ))}
  </>
)
