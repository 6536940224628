import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

interface IPortalProps {
  children: React.ReactNode
}

const Portal = ({ children }: IPortalProps) => {
  return createPortal(children, document.body)
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isShown: boolean
  toggle: () => void
  bodyProps?: React.HTMLAttributes<HTMLDivElement>
}

// eslint-disable-next-line react/display-name
export const Popover = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IProps>>(
  ({ children, isShown, bodyProps, toggle, ...props }, ref) => {
    const node = ref as React.RefObject<HTMLDivElement>

    const handleClickOutside = (e: any) => {
      if (node && node.current && !node.current.contains(e.target)) {
        toggle()
      }
    }

    const handleResize = () => {
      if (isShown) {
        toggle()
      }
    }

    useEffect(() => {
      if (isShown) {
        document.addEventListener('mousedown', handleClickOutside)
        window.addEventListener('resize', handleResize)
      } else {
        document.removeEventListener('mousedown', handleClickOutside)
        window.removeEventListener('resize', handleResize)
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        window.removeEventListener('resize', handleResize)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShown])

    return (
      <Portal>
        <div
          {...props}
          className={['popover', props.className].filter(Boolean).join(' ')}
          role="tooltip"
          ref={ref}
          style={{
            ...props.style,
            ...(isShown ? {} : { display: 'none' }),
          }}
        >
          <div className="arrow" data-popper-arrow />
          <div
            {...bodyProps}
            className={['popover-body p-0', bodyProps?.className].filter(Boolean).join(' ')}
            style={{ ...bodyProps?.style, position: 'static', top: 0, left: 0, display: 'block' }}
          >
            {children}
          </div>
        </div>
      </Portal>
    )
  },
)
